/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from './assets/logo.svg';
import comingSoon from './assets/coming-soon.svg';
import video from './assets/video.mp4';
import './App.css';
import { Component } from 'react';

export default class App extends Component {
  componentDidMount() {
    //   window.addEventListener('scroll', () => {
    //     const header = document.querySelector('header');
    //     const logo = document.querySelector('header img');

    //     if (window.scrollY > 20) {
    //       header.classList.add('bg-[#FCFFF4]');
    //       header.classList.remove('bg-transparent');
    //       logo.classList.replace('w-[60rem]', 'w-[8rem]');

    //     } else {
    //       header.classList.add('bg-transparent');
    //       header.classList.remove('bg-[#FCFFF4]');
    //       logo.classList.replace('w-[8rem]', 'w-[60rem]');
    //     }
    //   });
  }

  render() {
    return (
      <main className='flex flex-col items-center justify-center mx-4 lg:mx-24 h-[100vh]'>
        <img src={logo} alt="logo" className='w-[20rem] lg:w-[48rem] transition-all duration-700 ease-in-out transform opacity-90' />
        <img src={comingSoon} alt="coming-soon" className='w-[12rem] lg:w-[24rem] mt-4 o.pacity-90' />

        <video autoplay="autoplay" loop="loop" muted defaultMuted playsInline className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' >
          <source src={video} type="video/mp4" />
        </video>


        {/* <header className='fixed top-0 left-0 w-full flex items-center justify-center p-4 bg-transparent'>
          <img src={logo} alt="logo" className='w-[60rem] transition-all duration-700 ease-in-out transform' />
        </header>

        <img src='https://media.gucci.com/content/HeroRegularStandard_3200x1350/1703177159/HeroRegularStandard_Gucci-SpringSummer24-Dec23-03_001_Default.jpg' alt="gucci" className='w-full h-[100vh] mt-[28rem]' />

        <div className='grid grid-cols-2 grid-rows-2 gap-4 mt-4'>
          <img src='https://media.gucci.com/content/HeroRegularStandard_3200x1350/1703177159/HeroRegularStandard_Gucci-SpringSummer24-Dec23-03_001_Default.jpg' alt="gucci" className='w-full h-full object-cover' />
          <img src='https://media.gucci.com/content/HeroRegularStandard_3200x1350/1703177159/HeroRegularStandard_Gucci-SpringSummer24-Dec23-03_001_Default.jpg' alt="gucci" className='w-full h-full object-cover' />
          <img src='https://media.gucci.com/content/HeroRegularStandard_3200x1350/1703177159/HeroRegularStandard_Gucci-SpringSummer24-Dec23-03_001_Default.jpg' alt="gucci" className='w-full h-full object-cover' />
          <img src='https://media.gucci.com/content/HeroRegularStandard_3200x1350/1703177159/HeroRegularStandard_Gucci-SpringSummer24-Dec23-03_001_Default.jpg' alt="gucci" className='w-full h-full object-cover' />
        </div> */}
      </main>
    );
  }
}